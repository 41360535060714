export const environment = {
  production: true,
  eventsBaseUrl: 'https://gtk21n7v11.execute-api.eu-west-1.amazonaws.com/prod',
  photosBaseUrl: 'https://3ouy6j0r8a.execute-api.eu-west-1.amazonaws.com/prod',
  usersBaseUrl: 'https://bkqhzpsd5i.execute-api.eu-west-1.amazonaws.com/prod',
  notificationsBaseUrl: 'https://my3w5v0m4j.execute-api.eu-west-1.amazonaws.com/prod',
  paymentsBaseUrl: 'https://i7yzbjhy04.execute-api.eu-west-1.amazonaws.com/prod',
  eventPhotoPrefix: 'EVNT',
  profilePhotoPrefix: 'PRFL',
  apiKey: 'e4d3c2ba1d3c6f5e4f52ba16',
  sentryDsn: 'https://f6871d433473c26265a33257e40b4c5e@o4505632836354048.ingest.sentry.io/4505635002974208',
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
};
